import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios';

interface IOptionParams<T> {
  [key: string]: T;
}

interface IAxiosInstance {
  baseURL: string;
  headers?: IOptionParams<any>;
  // params?: IOptionParams<any>;
}

class Http {
  private instance: AxiosInstance;

  constructor(options: IAxiosInstance) {
    const { baseURL, headers } = options;

    this.instance = axios.create({
      baseURL,
      headers
    })
  }

  private handleResponse<T>(response: AxiosResponse<T>): T {
    return response.data;
  }

  private handleError(error: any): Promise<any> {
    // Обработка ошибок здесь, например, логирование ошибок или выброс исключения
    return Promise.reject(error);
  }

  public get<T>(resource: string, params?: IOptionParams<any>): Promise<T> {
    const config: AxiosRequestConfig = { params };

    return this.instance
      .get<T>(resource, config)
      .then(this.handleResponse)
      .catch(this.handleError);
  }

  // Добавить методы для других типов запросов (POST, PUT, DELETE) при необходимости
}

export const http =  new Http({
  baseURL: 'https://api.sm-center.ru/',
  headers: {
    // Заголовки
  },
});
