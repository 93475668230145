import { getList, activate, getConfig } from '@/api/accessObjects';
import { IAccessObjects } from '@/types/IAccessObjects'
import { IActivateParams } from '@/types/IActivateParams';
import { IStoreContext as IStoreState, IState } from '@/types/IStoreContext';
import { ActionTree, ActionContext } from 'vuex';

const actions: ActionTree<IStoreState, IAccessObjects> = {
  /**
   * Получает список объектов доступа по токену
   *
   * @param   {string}                   token  Токен доступа
   * @returns {Promise<IAccessObjects>}         Объект с данными объектов доступа
   */
  async fetchList(context: ActionContext<IStoreState, IAccessObjects>, token: string): Promise<IAccessObjects> {
    try {
      const response = await getList(token);
      return response;
    } catch(error) {
      console.error('Ошибка при получении списка объектов:', error);
      throw error;
    }
  },

  /**
   * Активирует объект доступа по параметрам активации
   *
   * @param    {IActivateParams}         params  Параметры активации объекта доступа
   * @returns {Promise<IAccessObjects>}          Объект с данными активированного объекта доступа
   */
  async activate(context: ActionContext<IStoreState, IAccessObjects>, params: IActivateParams): Promise<IAccessObjects> {
    try {
      const response = await activate(params);
      return response;
    } catch(error) {
      console.error('Ошибка при активации объекта:', error);
      throw error;
    }
  },

  /**
   * Настройки ЛК/МП
   *
   * @param context
   * @param token
   * @returns
   */
  async getConfig(context: ActionContext<IStoreState, IAccessObjects>, token: string): Promise<any> {
    try {
      const response = await getConfig(token);
      return response
    } catch(error) {
      console.error('Ошибка при активации объекта:', error);
      throw error;
    }
  }
}
export default {
  name: 'accessObjects',
  namespaced: true,
  actions,
}
