import { http } from '@/plugins/http';
import { IActivateParams } from '@/types/IActivateParams';

/**
 * Получить список объектов доступа
 *
 * @param {string}     token  Токен для авторизации
 *
 * @returns {Promise}         Обещание с результатом запроса
 */
export const getList = (token: string): Promise<any> => {
  return http.get(`/AccessObjects/${token}/List`);
};

/**
 * Активировать объект доступа
 *
 * @param {Object} params           Параметры активации
 * @param {string} params.token     Токен для авторизации
 * @param {string} params.objectId  Идентификатор объекта для активации
 *
 * @returns {Promise}               Обещание с результатом запроса
 */
export const activate = ({token, objectId}: IActivateParams): Promise<any> => {
  return http.get(`/AccessObjects/${token}/Activate`, { objectId });
};

/**
 * Возвращает настройки ЛК / МП, аналогично методу MobileAppSettings для указанного токена.
 * Результат аналогичен результату метода MobileAppSettings. Если токен не найден, то возвращается пустой объект.
 *
 * @param   {string}   token  Токен объекта
 *
 * @return  {Promise}         Список настроек
 */
export const getConfig = (token: string): Promise<any> => {
  return http.get(`/AccessObjects/${token}/Config`)
}
